'use client';

import Transitions from '@/components/ui-component/extended/Transitions';
import { useLocale } from '@/hooks/useLocale';
import { analyticsTrack } from '@/util/analytics';
import type { Language } from '@magicschool/supabase/types';
import { Avatar } from '@magicschool/ui/Avatar';
import { Box } from '@magicschool/ui/Box';
import { Grid } from '@magicschool/ui/Grid';
import { Paper } from '@magicschool/ui/Paper';
import { Tooltip } from '@magicschool/ui/Tooltip';
import { radius } from '@magicschool/ui/constants/radius';
import { zIndices } from '@magicschool/ui/constants/zIndices';
import TranslateTwoToneIcon from '@mui/icons-material/TranslateTwoTone';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Popper from '@mui/material/Popper';
import Typography from '@mui/material/Typography';
import type { Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { usePathname } from 'next/navigation';
import { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { languageLabels } from './lookup';
type LocalizationSectionProps = {
  analyticsEventName?: boolean;
};
const LocalizationSection = ({
  analyticsEventName
}: LocalizationSectionProps) => {
  const {
    locale,
    onLocaleChange
  } = useLocale();
  const pathname = usePathname();
  const intl = useIntl();
  const matchesXs = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<any>(null);
  const [language, setLanguage] = useState<string>(locale);
  const handleListItemClick = (_event: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLDivElement, MouseEvent> | undefined, lng: string) => {
    setLanguage(lng);
    onLocaleChange(lng as Language);
    setOpen(false);
    if (analyticsEventName) {
      const key = pathname.includes('signup') ? 'SignUp:Translate' : 'SignIn:Translate';
      analyticsTrack(key, {
        type: lng
      });
    }
  };
  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };
  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current?.contains(event.target)) {
      return;
    }
    setOpen(false);
  };
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);
  useEffect(() => {
    setLanguage(locale);
  }, [locale]);
  return <>
      <Box id="menu-list-grow" data-sentry-element="Box" data-sentry-source-file="index.tsx">
        <Tooltip title={<FormattedMessage id="tooltip.language" />} data-sentry-element="Tooltip" data-sentry-source-file="index.tsx">
          <Avatar component="button" variant="rounded" size="medium" color="secondary" clickable sx={{
          border: 'none'
        }} ref={anchorRef} aria-label={intl.formatMessage({
          id: 'tooltip.language'
        })} aria-controls={open ? 'menu-list-grow' : undefined} aria-haspopup="true" onClick={handleToggle} data-sentry-element="Avatar" data-sentry-source-file="index.tsx">
            {language !== 'en-us' && <Typography variant="h5" sx={{
            textTransform: 'uppercase'
          }} color="inherit">
                {language.slice(0, 2)}
              </Typography>}
            {language === 'en-us' && <TranslateTwoToneIcon sx={{
            fontSize: '1.3rem'
          }} />}
          </Avatar>
        </Tooltip>
      </Box>

      <Popper placement={matchesXs ? 'auto' : 'top-end'} open={open} anchorEl={anchorRef.current} role={undefined} transition sx={{
      zIndex: zIndices.overlay
    }} modifiers={[{
      name: 'offset',
      options: {
        offset: [matchesXs ? 0 : 0, 20]
      }
    }]} data-sentry-element="Popper" data-sentry-source-file="index.tsx">
        {({
        TransitionProps
      }) => <ClickAwayListener onClickAway={handleClose}>
            <Transitions position={matchesXs ? 'top-left' : 'top'} in={open} {...TransitionProps}>
              <Paper elevation={16}>
                {open && <List component="nav" sx={theme => ({
              width: '100%',
              minWidth: 200,
              maxHeight: 400,
              overflowY: 'auto',
              maxWidth: 280,
              bgcolor: theme.palette.background.paper,
              borderRadius: radius[1],
              [theme.breakpoints.down('md')]: {
                maxWidth: 250
              }
            })}>
                    {Object.entries(languageLabels).map(([code, label]) => {
                const [primary, ...rest] = label.split(' ');
                const secondary = rest.join(' ');
                return <ListItemButton key={code} selected={language === code} onClick={event => handleListItemClick(event, code)}>
                          <ListItemText primary={<Grid container>
                                <Typography color="textPrimary">{primary}</Typography>
                                <Typography variant="caption" color="textSecondary" sx={{
                      ml: '8px'
                    }}>
                                  {secondary}
                                </Typography>
                              </Grid>} />
                        </ListItemButton>;
              })}
                  </List>}
              </Paper>
            </Transitions>
          </ClickAwayListener>}
      </Popper>
    </>;
};
export default LocalizationSection;