'use client';

import { analyticsTrack } from '@/util/analytics';
import { useStore } from 'features/store';
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { FormattedMessage } from 'react-intl';
export const AlertComponent: React.FC = () => {
  const {
    formAlert,
    setField
  } = useStore(({
    AuthFormStoreData: af
  }) => ({
    formAlert: af.formAlert,
    setField: af.setField
  }), []);
  useEffect(() => {
    if (formAlert) {
      setField('formAlert')(null);
      if (formAlert.type !== 'analytics') {
        const fn = formAlert.type === 'success' ? toast.success : toast.error;
        const value = formAlert.messageId ? <FormattedMessage id={formAlert.messageId} /> : formAlert.message;
        fn(value ?? '', formAlert.duration ? {
          duration: formAlert.duration
        } : {});
      }
      analyticsTrack(formAlert.page, {
        message: formAlert.message ?? formAlert.messageId
      });
    }
  }, [formAlert, setField]);
  return null;
};