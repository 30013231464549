import MuiTooltip, { type TooltipProps as MuiTooltipProps } from '@mui/material/Tooltip';
import { forwardRef } from 'react';
export interface TooltipProps extends MuiTooltipProps {
  'aria-label'?: string;
  'data-test-id'?: string;
  ref?: React.Ref<HTMLDivElement>;
}
export const Tooltip = forwardRef<HTMLElement, TooltipProps>(({
  children,
  ...rest
}, ref) => {
  return <MuiTooltip ref={ref} {...rest}>
      {children}
    </MuiTooltip>;
});