import MuiPaper, { type PaperProps as MuiPaperProps } from '@mui/material/Paper';
import { forwardRef } from 'react';
export interface PaperProps extends MuiPaperProps {
  'aria-label'?: string;
  'data-test-id'?: string;
  ref?: React.Ref<HTMLDivElement>;
}
export const Paper = forwardRef<HTMLDivElement, PaperProps>(({
  children,
  ...rest
}, ref) => {
  return <MuiPaper ref={ref} {...rest}>
      {children}
    </MuiPaper>;
});