import { zIndices } from '@magicschool/ui/constants/zIndices';
import { Box } from '@magicschool/ui/Box';
import { space } from '@magicschool/ui/constants/space';
import { styled } from '@mui/material/styles';
export const StyledBanner = styled(Box)(({
  theme
}) => ({
  backgroundColor: theme.palette.warning.light,
  border: `1px solid ${theme.palette.warning.dark}`,
  width: '100%',
  zIndex: zIndices.aboveAll,
  padding: space(1),
  textAlign: 'center'
}));
export const StyledStickyBanner = styled(StyledBanner)({
  position: 'sticky',
  top: 0,
  left: 0,
  right: 0
});